import React from 'react';

export const About = (props) => {
  // Function to make the first word of a string bold
  const makeFirstWordBold = (str) => {
    const words = str.split(' ');
    if (words.length > 1) {
      return (
        <span>
          <strong>{words[0]}</strong> {words.slice(1).join(' ')}
        </span>
      );
    }
    return <strong>{str}</strong>;
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/about.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Mission Statement</h3>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{makeFirstWordBold(d)}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}>{makeFirstWordBold(d)}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
